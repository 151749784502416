<template>
  <v-card class="py-10 px-10">
    <v-card-title>
      <v-layout justify-center>
        <h1 class="color-text-main mb-3">AUTOCOM - OKAN</h1>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-layout justify-center>
        <h2 class="color-text-third mb-5">
          Autocom se ha unificado con Okan y ahora el inicio de sesión es mucho
          más fácil, solo con el correo corporativo o las credenciales de
          usuario de red, aquí tienes un ejemplo
        </h2>
      </v-layout>

      <v-row>
        <v-col cols="12">
          <video
            class="mt-3 mb-2"
            style="width: 100%"
            ref="videoA"
            id="videoAuto"
            src="../../assets/video/login_okan.mp4"
            controls
            autoplay
            muted
            loop
          />
        </v-col>
      </v-row>
      <!-- <v-form ref="form">
        <v-text-field
          :rules="[(v) => !!v || 'Este campo es requerido']"
          v-model="form.email"
          label="Usuario / correo electrónico"
          placeholder="Ingrese aquí el usuario"
          color="#034f79"
          outlined
        ></v-text-field>
        <v-text-field
          :rules="[
            (v) => !!v || 'Este campo es requerido',
            (v) =>
              (v && v.length >= 8) ||
              'La contraseña debe tener más de 8 caracteres',
          ]"
          counter
          v-model="form.password"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          label="Contraseña"
          placeholder="Ingrese aquí la contraseña"
          color="#034f79"
          outlined
          @click:append="show = !show"
          @keyup.enter="login()"
        ></v-text-field>
      </v-form> -->
    </v-card-text>
    <v-card-actions>
      <v-row justify="center">
        <v-col cols="12">
          <v-layout justify-center>
            <v-btn
              :loading="loading.btn"
              @click="login()"
              dark
              color="#212e57"
              rounded
              large
              block
            >
              <img class="mr-3" width="40" src="../../assets/img/okan.png" />
              <strong> IR A OKAN </strong>
            </v-btn>
          </v-layout>
        </v-col>
        <!-- <v-col cols="12">
          <v-layout justify-center>
            <v-btn :to="{ name: 'recover' }" small dark color="#034f79" text>
              <u> recuperar contraseña </u>
            </v-btn>
          </v-layout>
        </v-col> -->
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import { ROL } from "./../../utils/util";
import { login } from "./../../utils/auth";
import alert from "@/mixins/alert";
import { mapState, mapMutations } from "vuex";
import Api from "@/utils/api";

export default {
  mixins: [alert],
  name: "Login",
  data() {
    return {
      ROL: ROL,
      loading: {
        btn: false,
      },
      show: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapState(["infoUser", "roleName"]),
  },
  methods: {
    ...mapMutations(["setInfoUser", "setRoleName"]),
    login() {
      window.location.href = "https://login.okan.tools/login";
      // if (this.$refs.form.validate()) {
      //   this.loading.btn = true;
      //   Api.Auth()
      //     .login(this.form)
      //     .then((res) => {
      //       if (res.data.cod == 0) {
      //         login(res.data.data.access_token);
      //         let encodeUserRole = btoa("userRole");
      //         let encodeUserRoleName = btoa("userRoleName");
      //         let encodeValueUserRole = btoa(res.data.data.rol_id);
      //         let encodeValueUserRoleName = btoa(res.data.data.rol_name);
      //         localStorage.setItem(encodeUserRole, encodeValueUserRole);
      //         localStorage.setItem(encodeUserRoleName, encodeValueUserRoleName);
      //         this.setInfoUser(res.data.data.rol_id);
      //         this.setRoleName(res.data.data.rol_name);
      //         this.redirect(res.data.data.rol_id);
      //         this.alert(res.data.message, "Muy bien", "success");
      //       } else {
      //         this.alert(res.data.message, "Oops...", "error");
      //       }
      //     })
      //     .catch((error) => {
      //       this.alert(error.response.data.message, "Oops...", "error");
      //     })
      //     .finally(() => (this.loading.btn = false));
      // }
    },
    redirect(rolId) {
      switch (rolId) {
        case ROL.workforce:
          this.$router.push({ name: "listCampaign" });
          break;
        case ROL.directory:
          this.$router.push({ name: "commissionsDirectory" });
          break;
        case ROL.manager:
          this.$router.push({ name: "commissionsManager" });
          break;
        case ROL.payRollManager:
          this.$router.push({ name: "commissionsPayRollManager" });
          break;
        case ROL.coordinator:
          this.$router.push({ name: "commissionsCoordinator" });
          break;
        case ROL.accountManager:
          this.$router.push({ name: "commissionsAccountManager" });
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    document.getElementById("videoAuto").muted = true;
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/main";
</style>
